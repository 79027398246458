import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Signature extends React.Component {

  render() {
    return (
      <section className='section-container' style={{textAlign: `center`}}>
          Crafted with <FontAwesomeIcon className='color-red' icon="fa-heart" />
      </section>
    )
  }
}