import React, { Component } from 'react'

export default class CertEntry extends Component {
    render() {
        return (
            <li className='exp-list-element'>
                <div className='display-flex flex-column full-width align-self-center'>
                    <span className='exp-list-position-1'>{this.props.name}</span>
                    <span className='exp-list-position-2'>{this.props.type} | {this.props.status} | {this.props.date}</span>
                </div>
            </li>
        )
    }
}

