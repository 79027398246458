import React from 'react'
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
        <div>Chyba się zgubiłeś!</div>
        <p><Link to ="/">Powrót do strony głównej</Link></p>
    </>
  )
}

export default NotFound