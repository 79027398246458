import React, { Component } from 'react'

export default class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClick = () => {
        if (this.props.onClick) {
          this.props.onClick(); 
        }
      };

    render() {
        return (
            <div className='display-flex' onClick={this.handleClick}>
                {(!this.props.even && <div className='tile-filler'></div>)}
                <div className='display-flex tile'>
                    {(this.props.even && 
                        <div className='tile-image'>
                            <img src={this.props.image} alt={this.props.name} />
                        </div>)
                    }
                    
                    <div className={`tile-body display-flex flex-column justify-center ${!this.props.even ? "align-right" : ""}`}>
                        <div className='tile-header color-3'>
                            {this.props.name}
                        </div>
                        <div className='tile-description'>
                            {this.props.description}
                        </div>
                    </div>
                    
                    {(!this.props.even && 
                        <div className='tile-image'>
                            <img src={this.props.image} alt={this.props.name} />
                        </div>)
                    }

                </div>
                {(this.props.even && <div className='tile-filler'></div>)}
            </div>
        )
    }
}

