import React from 'react';
import Company from './ExperienceElements/Company';
import CompanyEntry from './ExperienceElements/CompanyEntry';
import PageHeader from './sharedElements/PageHeader'

export default class Experience extends React.Component {

  render() {
    return (
      <section className='section-container background-dark' id={this.props.id}>
          <PageHeader value={this.props.name} />
          <div className='display-flex justify-center align-start flex-wrap'>
            <Company companyName="Intercom Sp. z o.o.">
              <CompanyEntry last="true" position="Grafik Komputerowy" type="" dates="styczeń 2024 · obecnie" />
              <Company removeClass="true" companyName="Intercom Marcin Masier">
                <CompanyEntry last="true" position="Grafik Komputerowy, Social Media" type="" dates="marzec 2023 · grudzień 2023" />
              </ Company>
            </ Company>
            <Company companyName="INVETTE HOLDING GROUP SP. Z.O.O.">
              <CompanyEntry position="Koordynator ds. Social Media" type="" dates="paź 2021 · luty 2023" />
              <CompanyEntry position="Młodszy specjalista ds. Social Media" type="" dates="maj 2021 · wrzesień 2021" />
              <CompanyEntry last="true" position="Młodszy specjalista ds. Social Media" type="Staż" dates="styczeń 2021 · kwiecień 2021" />
            </ Company>
            <Company companyName="Fucco Design Erwin Garbarczyk">
              <CompanyEntry position="Grafik" type="Staż" dates="lipiec 2018 · sierpień 2018" />
              <CompanyEntry last="true" position="Grafik" type="Praktyki" dates="listopad 2018" />
            </ Company>
            {/* <Company companyName="INSIGHT AND MOTIVATION LTDINSIGHT">
              <CompanyEntry last="true" position="Moderacja strony WWW" type="Płatny staż" dates="lis 2017" />
            </ Company> */}
          </div>
      </section>
    )
  }
}