import { createContext } from "react";

export const MessageContext = createContext();

export function getAPIAdress(route) {
    return "https://api.saradesign.pl/" + route;
}

export function getMessage(key) {
    let messages = MessageContext._currentValue;
    if(!Array.isArray(messages)) return;

    for(let message of MessageContext._currentValue) {
        if(message["key"] === key) {
            return message["message"];
        }
    }

    return "no msg for " + key;   
}