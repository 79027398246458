import React from 'react';
import Certificate from './certificatesElements/Certificate';
import PageHeader from './sharedElements/PageHeader'
import { getAPIAdress } from '../context/MessageContext'
import CertEntry from './certificatesElements/CertEntry';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export default class Certificates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      certs: {}
    }
  }

  fetchData() {
    const promise = fetch(getAPIAdress('cert'))
    .then((response) => {
        return response.json()
    })
    .catch((err) => {
        console.log(err.message);
    });

    promise.then((response) => {
        this.setState({ready: true, certs: response});
    })
  }

  componentDidMount() {
      this.fetchData();
  }

  getCerts() {
    let certs = [];
    let stateCerts = this.state.certs;
    if(!Array.isArray(stateCerts)) return;
    for(let cert of stateCerts) {
      let key = cert["organizator"];
      if(certs[key] === undefined) {
        certs[key] = [];
      }
      certs[key].push({id: cert["id"], key: cert["id"], name: cert["name"], date: cert["data"], status: cert["status"], type: cert["type"]});
    }
    return certs;
  }

  renderCerts() {
    let elements = [];
    for(const [key, value] of Object.entries(this.getCerts())) {
      let entries = [];
      
      for(let entry of value) {
        entries.push(<CertEntry key={entry.id} name={entry.name} type={entry.type} status={entry.status} date={entry.date} />)
      }
      elements.push(
        <Certificate key={key} organizator={key}>{entries}</Certificate>
      )
    }
    return elements;
  }

  render() {
    if(!this.state.ready) {
      return (
        <section className='section-container background-dark' id={this.props.id}>
          <PageHeader value={this.props.name} />
          <div className='display-flex flex-column align-center'></div>
        </section>
      )
    }
    return (
      <section className='section-container' id={this.props.id}>
          <PageHeader value={this.props.name} />
          <div className='cert-wrapper'>
            <ResponsiveMasonry
              columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
              <Masonry>
                { this.renderCerts() }
              </Masonry>
            </ResponsiveMasonry>
          </div>
      </section>
    )
  }
}