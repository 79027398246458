import React from 'react'

export default class Certificate extends React.Component {

    render() {
        return(
            <div className='skill width-auto'>
                <span className='bold color-3'>{this.props.organizator}</span>
                <ul className='exp-list'>
                    {this.props.children}
                </ul>
            </div>
        )
    }
}

