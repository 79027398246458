import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NavContext } from '../context/NavContext';
import { getMessage } from '../context/MessageContext';

import Button from '../elementComponents/Button';

export default class Home extends Component {

  render() {
    return (
      <>
        <main scrollable-element="true" className='main-container' id={this.props.id}>
          <div className="background-image"></div>
          <div className='home-container'>
            <div className='home-info'>
              <h1>{ getMessage('home-welcome-1') }</h1>
              <p>{ getMessage('home-welcome-2') }</p>
              <p>{ getMessage('home-welcome-3') }</p>
              <NavContext.Consumer>
                {context => ( 
                  <>
                    <Button value={ getMessage('button-home-1') } onClick={() => context.scrollToSection('footer')} />
                    <Button outline="true" value={ getMessage('button-home-2') } onClick={() => context.scrollToSection('projects')} />
                  </>
              )}
              </NavContext.Consumer>
            </div>
          </div>
          <NavContext.Consumer>
              {context => ( 
                <div className='home-bottom'>
                  <div onClick={() => context.scrollToSection('projects')} className='home-scroll-icon anim-hover-2s'>
                    <FontAwesomeIcon icon="fa-angle-down" />
                  </div>
                </div>
              )}
            </NavContext.Consumer>
        </main>
      </>
    )
  }
}

;