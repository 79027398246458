import React, { Component } from 'react';
import './App.css';

import Navbar from './components/Navbar';
import Home from './components/pageComponents/Home';
import Skills from './components/pageComponents/Skills';
import Projects from './components/pageComponents/Projects';
import Experience from './components/pageComponents/Experience';
import Footer from './components/pageComponents/Footer';
import Signature from './components/pageComponents/Signature';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { MessageContext, getAPIAdress } from './components/context/MessageContext';
import Certificates from './components/pageComponents/Certificates';
import { BrowserRouter, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import NotFound from './components/pageComponents/NotFound2';
import ProjectsPage from './components/projectsRoute/ProjectsPage'

library.add(fas);

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: {},
        }

    }

    componentDidMount() {
        const promise = fetch(getAPIAdress('messages'))
        .then((response) => {
            return response.json()
        })
        .catch((err) => {
            console.log(err.message);
        });

        promise.then((response) => {
            this.setState({messages: response});
        })
    }

    appComponents() {
        return(
            <Navbar>
                <Home id="home" name="Home" />
                <Projects id="projects" name="Projekty" />
                <Skills id="skills" name="Umiejętności" />
                <Experience id="experience" name="Doświadczenie" />
                <Certificates id="certificates" name="Kursy" />
                <Footer id="footer" name="Kontakt" />
                <Signature />
            </Navbar>
        )
    }

    projectsComponents() {
        let { id } = useParams();
        return <ProjectsPage projectId={id} />
    }

    render() {
        return (
            <div className='App'>
                <MessageContext.Provider messages="test" value={this.state.messages}>
                    <BrowserRouter>
                        <Routes>
                            <Route exact path='/' element ={this.appComponents()} /> 
                            <Route path='/projects/:id' element ={<ProjectsPageWrapper />} /> 
                            <Route path='*' element ={<NotFound/>} />
                        </Routes>
                    </BrowserRouter>
                </MessageContext.Provider>
            </div>
        )
    }
}

const ProjectsPageWrapper = (props) => {
    let { id } = useParams();
    const navigate = useNavigate(); 
    return <ProjectsPage projectId={id} navigate={navigate} />;
};