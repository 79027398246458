import React, { Component } from 'react';
import SectionHeader from './sharedElements/PageHeader';
import Skill from './skillsElements/Skill';
import { getAPIAdress } from '../context/MessageContext';


export default class Skills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      skills: {}
    }
  }

  componentDidMount() {
    const promise = fetch(getAPIAdress('skills'))
    .then((response) => {
        return response.json()
    })
    .catch((err) => {
        console.log(err.message);
    });

    promise.then((response) => {
        this.setState({ready: true, skills: response});
    })
  }

  renderSkills() {
    let skills = [];
    let stateSkills = this.state.skills;
    if(!Array.isArray(stateSkills)) return;
    for(let skill of stateSkills) {
      skills.push(< Skill key={skill["id"]} name={skill["name"]} points={skill["points"]} desc={skill["description"]} />)
    }
    return skills;
  }

  render() {
    if(!this.state.ready) {
      return (
        <section className='section-container' id={this.props.id}>
          <SectionHeader value={this.props.name}></SectionHeader>
        </section>
      )
    }
    return (
      <section className='section-container' id={this.props.id}>
        <SectionHeader value={this.props.name}></SectionHeader>
        <div className='skills-holder'>
          {/* <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
          >
            <Masonry gutter="10px"> */}
              { this.renderSkills() }
            {/* </Masonry>
          </ResponsiveMasonry> */}
        </div>
      </section>
    )
  }

}

