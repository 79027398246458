import React, { Component } from 'react'

export default class CompanyEntry extends Component {
    render() {
        return (
            <li>
                <span className={`exp-list-dot ${this.props.last ? 'no-before' : ''}`}></span>
                <div className='exp-list-element exp-list-element--path'>
                    <div>
                        <div className="display-flex" tabIndex="-1" aria-hidden="true"></div>
                    </div>
                    <div className='display-flex flex-column full-width align-self-center'>
                        <span className='exp-list-position-1'>{this.props.position}</span>
                        <span className='exp-list-position-2'>{this.props.type}</span>
                        <span className='exp-list-position-3'>{this.props.dates}</span>
                    </div>
                </div>
            </li>
        )
    }
}

