import React from 'react';
import { getAPIAdress } from '../context/MessageContext';
import ProjectCard from './ProjectCard';

class ProjectsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navigate: props.navigate,
            category: null,
            project: null,
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        const { projectId } = this.props;
        this.setState({ loading: true, error: null });
        this.fetchCategory(projectId);
        this.fetchProject(projectId);
    }

    fetchCategory(id) {
        fetch(getAPIAdress(`tiles`))
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                data.forEach((category) => {
                    if(category.id == id) {
                        this.setState({category: category});
                        return;
                    }
                });
            })
            .catch((error) => this.setState({ error: error.message, loading: false }));
    }

    fetchProject(id) {
        fetch(getAPIAdress(`projects/${id}`))
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => this.setState({ project: data, loading: false }))
            .catch((error) => this.setState({ error: error.message, loading: false }));
    }

    render() {
        const { project, category, loading, error } = this.state;

        if (loading) {
            return <ProjectCard navigate={this.state.navigate} name={"Ładowanie"} image={""} tile_description={""} description={""} images={[]} />;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div>
                {project && category ? (
                    <ProjectCard 
                        navigate={this.state.navigate} 
                        name={category.name} 
                        image={category.image}
                        description={category.description} 
                        tile_description={category.tile_description}
                        images={project}
                    />
                ) : (
                    <div>Project not found</div>
                )}
            </div>
        );
    }
}

export default ProjectsPage;