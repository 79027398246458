import React from 'react'

function Skill(props) {
  return (
    <div className='skill'>
        <div className='skill-name'>{props.name} <span className='float-right font-small'>{props.points}</span></div>
        
        <div className="progressbar">
            <div className="progress-value" style={{width: `100%`}}></div>
        </div>
        <div className='skill-desc'>
            {props.desc}
        </div>
    </div>
  )
}

export default Skill