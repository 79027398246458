import React, { Component } from 'react'

export default class Company extends Component {
    render() {
        return (
            <div className={`${this.props.removeClass ? 'mt-1' : 'exp skill '}`}>
                {/* <div className='exp-inner'> */}
                    {this.props.companyName}<ul className='exp-list'>
                        {this.props.children}
                    </ul>
                {/* </div> */}
            </div>
          )
    }
}

