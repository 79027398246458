import React, { Component } from 'react';
import SectionHeader from './sharedElements/PageHeader';
import { getMessage } from '../context/MessageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

export default class Footer extends Component {

  render() {
    return (
      <section scrollable-element="true" className='section-container background-dark' id={this.props.id}>
        <SectionHeader value={this.props.name}></SectionHeader>
        <div className='footer-wrapper'>
          <div className='row'>
            <div className='col-6 display-flex flex-column justify-center'>
              <div className='row'>
                <a className='color-white text-decoration-none' href='tel:+48889614371'><FontAwesomeIcon className='color-3'  icon="fa-phone" /> { getMessage('footer-number') }</a>
              </div>
              <div className='row'>
                <a className='color-white text-decoration-none' href='mailto:sara.nowak99@gmail.com'><FontAwesomeIcon className='color-3' icon="fa-at" /> { getMessage('footer-mail') }</a>
              </div>
              <div className='row'>
                <a target='_blank' rel="noopener noreferrer" className='color-white text-decoration-none' href='https://www.linkedin.com/in/sara-nowak-0003581b9/'><FontAwesomeIcon className='color-3' icon="fa-brands fa-linkedin" /> { getMessage('footer-linkedin') }</a>
              </div>
            </div>
            <div className='col-6 align-center'>
              {getMessage('footer-1')}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

