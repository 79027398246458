import React from 'react'

function SectionHeader(props) {
  return (
    <div className='section-header-holder'>
        <div className='section-header'>{props.value}</div>
    </div>
    
  )
}

export default SectionHeader