import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavLink from './elementComponents/NavLink';
import { NavContext } from './context/NavContext';
import withLocation from './context/withLocation';
import { useLocation } from 'react-router-dom';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            click: false,
            button: false,
            smallNav: false,
            currentSection: 'home',
        };
        this.handleScroll = this.handleScroll.bind(this);
    }


    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.location.state && this.props.location.state.toProjects) {
            this.scrollToSection("projects");
            
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const bodyRect = document.body.getBoundingClientRect();
        this.props.children.forEach((section, key) => {
            if(section.props.id === undefined) return;
            const element = document.getElementById(section.props.id);
            if(element === undefined) return;
            const elementRect = element.getBoundingClientRect();
            const offset = elementRect.top - bodyRect.top;
            if(window.pageYOffset >= offset + this.getOffset()-1) {
                this.setState({currentSection: section.props.id});
            }
        })

        if(window.pageYOffset > 0) {
            setTimeout(() => {
                this.setState({smallNav: true})
            }, 50);
            
            return;
        }
        setTimeout(() => {
            this.setState({smallNav: false});
        }, 50);

        
    }

    handleClick () {
        const currentClick = this.state.click;
        this.setState({click: !currentClick});
    }

    getOffset() {
        if(window.innerWidth < 960) return -80;
        return this.state.smallNav ? -50 : -80;
    }

    scrollToSection(e) {
        const element = document.getElementById(e);
        const yOffset = this.getOffset(); 
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        this.setState({click: false});
    }

    render() {
        return (
            <>
                <nav className={`navbar ${this.state.smallNav ? 'small' : ''}`}>
                    <div className='navbar-container'>
                        <div className='menu-icon' onClick={() => this.handleClick()}>
                            {this.state.click ? <FontAwesomeIcon icon="fa-xmark" /> : <FontAwesomeIcon icon="fa-bars" />}
                        </div>
                        <ul className={`${this.state.click ? 'nav-menu active' : 'nav-menu'} ${this.state.smallNav ? 'small' : ''}`}>
                            {this.props.children.map((section, i) =>
                                <NavLink name={section.props.name} active={this.state.currentSection === section.props.id ? true : false} onClick={() => this.scrollToSection(`${section.props.id}`)} key={i} />
                            )}
                        </ul>
                    </div>
                </nav>
                <NavContext.Provider value={{
                    scrollToSection : section => {
                        this.scrollToSection(section)
                    }
                }}>
                    {this.props.children}
                </NavContext.Provider>
            </>
        )
    }
}

export default withLocation(Navbar);