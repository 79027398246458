import React, { Component } from 'react';
import Tile from './projectsElements/Tile';
import SectionHeader from './sharedElements/PageHeader';
import { getAPIAdress } from '../context/MessageContext';
import { useNavigate } from 'react-router-dom';

export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const promise = fetch(getAPIAdress('tiles'))
    .then((response) => {
        return response.json()
    })
    .catch((err) => {
        console.log(err.message);
    });

    promise.then((response) => {
        this.setState({categories: response});
    })
  }

  renderCats() {
    let cats = [];
    let stateCats = this.state.categories;
    if(!Array.isArray(stateCats)) return;
    let i = 0;
    for(let cat of stateCats) {
      cats.push(
        <TileWrapper key={cat["name"]} id={cat["id"]} name={cat["name"]} description={cat["description"]} image={cat["image"]} even={i % 2 == 0} />
      );
      i++;
    }
    return cats;
  }
  
  render() {
    return (
      <section className='section-container background-dark' id={this.props.id}>
        <div className='container-xl'>
          <SectionHeader value={this.props.name}></SectionHeader>
          { this.renderCats() }
        </div>
      </section>
    )
  }
}

const TileWrapper = (props) => {
  const navigate = useNavigate(); 
  return (
    <Tile
      id={props.id} 
      name={props.name} 
      description={props.description} 
      image={props.image} 
      even={props.even}
      onClick={() => { navigate(`/projects/${props.id}`); }}
    />
  );
};