import React from "react";
import Button from "../elementComponents/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"


class ProjectCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navigate: props.navigate,
            name: props.name,
            image: props.image,
            description: props.description,
            tile_description: props.tile_description,
            images: props.images,
        };
    }

    handleRedirect = () => {
        this.state.navigate('/', { state: { toProjects: true } }); 
    };

    render() {
        return (
            <>
                <div className="project-back-button">
                    <Button value={
                        <>
                            <FontAwesomeIcon icon="fa-solid fa-chevron-left" className="mr-1" />
                            Powrót
                        </>
                    } 
                    onClick={this.handleRedirect} 
                    />
                </div>
                <div className="card-container">
                    <div className="card-content">
                        <div className="card-title">{this.state.name}</div>
                        {/* <div className="card-description">{this.state.description}</div> */}
                        <div
                            className="card-image"
                            style={{ backgroundImage: `url(${this.state.image})` }}
                        >
                        </div>
                    </div>
                    <div className="image-list">
                        <div className="display-flex flex-column card-description" dangerouslySetInnerHTML={{__html: this.state.tile_description}}></div>
                        {this.state.images.map((image, index) => (
                            <div
                                className="image-item"
                                key={index}
                                style={{ backgroundImage: `url(${image.path})` }}
                            />
                        ))}
                    </div>
                </div>
            </>

        );
    }
}

export default ProjectCard;